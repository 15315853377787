import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "results", "form" ]

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 500)
  }

  handleResults(event) {
    let [_data, _status, xhr] = event.detail;
    event.target.dataset.targets.split(' ').forEach((target) => {
      document.querySelector(target).innerHTML = xhr.response;
  
      const newUrl = xhr.responseURL;
      history.pushState({}, null, newUrl);
    });
  }
}
