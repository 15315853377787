import { Controller } from "stimulus";

export default class extends Controller {
  reloadData(event) {
    var targets = [];
    let button;
    if (event.target.tagName === 'BUTTON' || event.target.tagName == 'A') {
      button = $(event.target);
    } else {
      button = $(event.target).parentsUntil('*[data-controller="reload"]').last();
    }
    [button, ...button.children()].forEach((child) => {
      $(child).on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
      })
    });
    targets = button.data('targets').split(' ')
    $(document).one('ajax:success', () => {
      targets.forEach((target) => {
        this.reloadTarget(target);
      });
      [button, ...button.children()].forEach((child) => {
        $(child).off('click');
      });
    })
  }

  reloadTarget(target) {
    var container = document.querySelector(`${target} div`);
    if (container) {
      var event = new Event('refresh');
      container.dispatchEvent(event)
    }
  }
}
