import { Controller } from "stimulus";
import $ from 'jquery'
import 'select2'

export default class extends Controller {
  static targets = ['select']

  connect() {
    if (!this.hasSelectTarget) return

    this.selectJQuery = $(this.selectTarget)
    this.selectJQuery.select2({
      minimumInputLength: 3,
      width: '100%',
      ajax: {
        url: '/tickets_autocomplete.json',
        dataType: 'json',
        data: function (params) {
          return { q: params.term }
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: `${item.uid} - ${item.summary}`,
                id: item.id
              }
            })
          };
        },
        delay: 250,
      }
    })

    this.selectJQuery.on('select2:select', function (e) {
      let data = e.params.data;
      const token = $('meta[name="csrf-token"]').attr('content');
      $('*[data-behavior="autocomplete"]').val('');
      $.ajax(`/tickets/${data.id}/intervals?event=start`, {
        "method": 'post',
        "data": { authenticity_token: token }
      }).done(function () {
        const event = new Event('refresh');
        let refreshContainers = ['#tickets-container', '#intervals-container', '#timer-container', '#pairing-container', '#team-dashboard-container', '#personal-dashboard-intervals-container', '#personal-dashboard-tickets-container']
        refreshContainers.forEach((target) => {
          var container = document.querySelector(`${target} div`);
          if (container) {
            container.dispatchEvent(event)
          }
        });
      });
    });
    document.addEventListener('keydown', this.#switchSearch)
  }

  #switchSearch = ({ repeat, metaKey, ctrlKey, key }) => {
    if (repeat || !((metaKey || ctrlKey) && key === 'k')) return

    if (this.selectJQuery.select2('isOpen')) {
      this.selectJQuery.select2('close')
    } else {
      this.selectJQuery.select2('open')
    }
  }
}
