// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
// const context = require.context("./", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))

import AutosaveController from "./autosave_controller"
import ClipboardController from "./clipboard_controller"
import DatepickerController from "./datepicker_controller"
import GlobalSearchController from "./global_search_controller"
import IntervalController from "./interval_controller"
import NavigationController from "./navigation_controller"
import ReloadController from "./reload_controller"
import SearchController from "./search_controller"
import Select2SearchController from "./select2_search_controller"
import StandupController from "./standup_controller"
import TickController from "./tick_controller"
import TickDownController from "./tick_down_controller"
import TicketController from "./ticket_controller"
import TooltipController from "./tooltip_controller"

application.register('autosave', AutosaveController)
application.register('clipboard', ClipboardController)
application.register('datepicker', DatepickerController)
application.register('global-search', GlobalSearchController)
application.register('interval', IntervalController)
application.register('navigation', NavigationController)
application.register('reload', ReloadController)
application.register('search', SearchController)
application.register('select2-search', Select2SearchController)
application.register('standup', StandupController)
application.register('tick', TickController)
application.register('tick-down', TickDownController)
application.register('ticket', TicketController)
application.register('tooltip', TooltipController)

// import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register('dropdown', Dropdown)
// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)
