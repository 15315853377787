import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select"]

  startTicket(event) {
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex]
    const url = selectedOption.getAttribute('data-url')

    if (url) {
      const form = document.createElement('form')
      form.method = 'post'
      form.action = url

      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      const csrfInput = document.createElement('input')
      csrfInput.type = 'hidden'
      csrfInput.name = 'authenticity_token'
      csrfInput.value = csrfToken
      form.appendChild(csrfInput)

      document.body.appendChild(form)
      form.submit()
    }
  }
}
